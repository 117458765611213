@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600;700&display=swap");

* {
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  margin: 0;
}

.shadow {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.page-container {
  margin: 2em;
  a {
    color: black;
  }
  h1 {
    text-align: center;
  }
}
