form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
  label {
    font-weight: bold;
    display: flex;
    flex-direction: column;
  }
  input[type="text"],
  input[type="email"],
  textarea {
    width: 25em;
    resize: none;
    margin-bottom: 2em;
  }
  button {
    width: 10em;
  }
}

.success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5em;
}

.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .youtube-link {
    text-align: center;
    margin-top: 3em;
    margin-bottom: 1em;
    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: dodgerblue;
      font-size: 1.3em;
    }
    .youtube-icon {
      font-size: 3em;
      color: red;
    }
  }
  h1 {
    margin-bottom: 0.5em;
    font-size: 5em;
  }
  p {
    max-width: 820px;
    margin-bottom: 1em;
    // text-align: center;
  }
  label {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .final-p-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 3em;
  }
  .final-paragraph {
    font-size: 1.5em;
    text-align: center;
    width: 500px;
  }
  .about-interview {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3em;
    border: solid #333 1px;
    border-radius: 1em;
    padding: 2em;
    width: 500px;
    margin: 5em auto;

    ul {
      margin-top: 2em;
    }
    li {
      max-width: 400px;
      margin-bottom: 1em;
    }
  }
  button {
    margin: auto;
    border: none;
    border-radius: 3em;
    padding: 1em;
    font-weight: bold;
    background-color: dodgerblue;
    color: white;
    margin-top: 1em;
    cursor: pointer;
  }
  .form-select-button {
    width: 250px;
    background: red;
    cursor: pointer;
    margin-bottom: 3em;
  }

  form {
    input,
    input[type="number"],
    textarea {
      padding: 1em;
      margin-top: 0.5em;
      border-radius: 0.5em;
      border: 1px solid black;
      width: 400px;
      margin-bottom: 1.5em;
      &:focus {
        outline: none;
        border: 1px solid dodgerblue;
      }
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
