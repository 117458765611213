.about-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  .hey-coach {
    margin-top: 1rem;
    font-size: 2rem;
    font-weight: bold;
    font-family: "Roboto Condensed", sans-serif;
    color: white;
    background: rgba(0, 0, 0, 0.815);
    text-align: center;
    padding: 1em;
    width: 100vw;
  }

  .about-description {
    width: 95%;
    max-width: 1000px;
    font-size: 1.2em;
    padding: 1em;
  }

  .contact {
    background: #333;
    padding: 100px;
    margin-top: 100px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      font-size: 1.5em;
      line-height: 1.2em;
    }
    a {
      color: dodgerblue;
      &:hover {
        color: red;
      }
    }
  }
}
